import axios from 'axios';
import eventBusService, { EXPIRED_SESSION_EVENT } from '../../services/eventBusService';
import { getItem, setItem } from '../../utility/localStorageControl';
import adapter from '../../normalizer';
import { mocks } from '../../mocks/clientes';
import keycloakUserData from "../../mocks/keycloakUser.json";
import { errorGlobal } from '../../utility/constants';

const loggedInKey = 'loggedIn';
const personalizarKey = 'pers';
const API_URL = process.env.REACT_APP_API_ENDPOINT || "https://staging.api.mav-cpd.com.ar/";

const client = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

const keycloakClient = axios.create({
  baseURL: "https://identity.mav-cpd.com.ar",
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    "access-control-allow-origin": "*",
    'Access-Control-Allow-Headers': 'Content-Type, Authorization',
    'Access-Control-Allow-Methods': '*',
  },
});

//const keycloakClient = extra => fetch("https://identity.mav-cpd.com.ar/realms/mav-cheques/protocol/openid-connect/token", extra);

const stringify = (obj) => {
  return Object.keys(obj)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]))
    .join('&');
}

const loginKeyCloak = async (data = {}) => {
  let dataSend = stringify({
    'client_id': 'mav-cheques-public-client',
    'grant_type': 'password',
    'scope': 'email openid',
    'username': data.username,
    'password': data.password
  });

  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: 'https://identity.mav-cpd.com.ar/realms/mav-cheques/protocol/openid-connect/token',
    // headers: { 
    //   'Content-Type': 'application/x-www-form-urlencoded',
    //   "access-control-allow-origin": '*',
    //   'Access-Control-Allow-Headers': 'Content-Type, Authorization',
    //   'Access-Control-Allow-Methods': '*',
    //   'Access-Control-Allow-Credentials': 'true'
    // },
    data : dataSend
  };
  
  const respuestaAxios = await axios.request(config);
  respuestaAxios.data && respuestaAxios.data.access_token && setItem("loggedIn", {access: respuestaAxios.data.access_token});
  respuestaAxios.data.access = respuestaAxios.data.access_token;
  
  return respuestaAxios;

  // try {
  //   const urlencoded = new URLSearchParams();
  //   urlencoded.append("client_id", "mav-cheques-public-client");
  //   urlencoded.append("grant_type", "password");
  //   urlencoded.append("scope", "email openid");
  //   urlencoded.append("username", data.username);
  //   urlencoded.append("password", data.password);

  //   // var data = new FormData();
  //   // data.append("client_id", "mav-cheques-public-client");
  //   // data.append("grant_type", "password");
  //   // data.append("scope", "email openid");
  //   // data.append("username", data.username);
  //   // data.append("password", data.password);

  //   // data.client_id = "mav-cheques-public-client";
  //   // data.grant_type = "password";
  //   // data.scope = "email openid";
  //   // data.username = data.username;
  //   // data.password = data.password;

  //   let response = await keycloakClient({
  //     method: 'POST',
  //     url: `/realms/mav-cheques/protocol/openid-connect/token`,
  //     data: urlencoded,
  //   });


    // let response = await keycloakClient({
    //   method: 'POST',
    //   body: JSON.stringify(data),
    //   headers: {
    //     'Content-Type': 'application/json'
    //   }
    // });
    //response.data && response.data.access_token && setItem("loggedIn", {access: response.data.access_token});
    //response.data.access = response.data.access_token;
    
  //   return response;
  // } catch (error) {
  //   console.log("loginKeycloak", error);
  //   errorGlobal(error);
  // }
};

const refreshTokenFc = async (data = {}) => {
  data.client_id = "mav-cheques-public-client";
  data.grant_type = "refresh_token";
  let response = client({
    method: 'POST',
    url: `/realms/mav-cheques/protocol/openid-connect/auth`,
    data: data,
  });
  response.data.access = response.data.access_token;
  return response;
}


class DataService {
  async get(path = '', headers) {
    return client({
      method: 'GET',
      url: path,
      headers
    });
  }

  async post(path = '', data = {}) {
    return client({
      method: 'POST',
      url: path,
      data: JSON.stringify(data),
    });
  }

  async patch(path = '', data = {}, headers) {
    return client({
      method: 'PATCH',
      url: path,
      data: JSON.stringify(data),
      headers: {
        body: headers
      }
    });
  }

  async put(path = '', data = {}) {
    return client({
      method: 'PUT',
      url: path,
      data: JSON.stringify(data),
    });
  }

  async delete(path = '') {
    return client({
      method: 'DELETE',
      url: path,
    });
  }
}

client.interceptors.request.use(
  config => {
    const requestConfig = config;
    const { headers } = config;
    const id = getItem(personalizarKey) && getItem(personalizarKey).id;
    //if (id && !config.url.includes("/users/2")) headers['Client-Id'] = id;
    if (id) headers['Client-Id'] = id;
    requestConfig.headers = {
      'Authorization': getItem(loggedInKey) && getItem(loggedInKey).access ? `Bearer ${getItem(loggedInKey).access}` : null,
      ...headers,
    };
    if(headers.export === "xls" || headers.export === "zip") {
      requestConfig.data = null;
      requestConfig.headers["Content-Type"] = "blob"
      //requestConfig.headers = {'Content-Type': 'blob', ...requestConfig.headers}
      requestConfig["responseType"] = 'arraybuffer';
    }
    return requestConfig;
  },
  error => {

    console.log(error);
    return Promise.reject(error)
  }
);


client.interceptors.response.use(
  response => {
    if(response.config.method === "get") {
      const convierto = JSON.stringify(mocks);
      const parseo = JSON.parse(convierto);
      const c1 = response.config.url.replace(/(=)([^&]+)/g, '$1number');
      const rompo = c1.split('?');
      let resultado = rompo[0]; 
      let segmentos;
      if(rompo.length > 1) {
        segmentos = rompo[1].split('&').filter(seg => !seg.includes('limit') && !seg.includes('offset'));
        if (segmentos.length > 0) {
          resultado += '?' + segmentos.join('&');
        }
      }
      resultado = resultado.replace(/\/$/, '');
      // esto es para los endpoint que son: /users/2. Reemplaza todos los numeros por la palabra id, resultando en /users/id
      const c2 = resultado.replace(/\/(\d+)(?=\/|$)/g, '/id');

      const mockActual = parseo.find(mock => mock.url === c2);

      // en este if entra siempre cuando es la primer consulta de la pantalla actual
      if(mockActual) return adapter(response.data, mockActual);
      // en este else entra cuando se usan filtros u ordenamientos
      else {
        const match = response.config.url.match(/\/([^/?]+)/);
        if (match) {
          const capturoPalabra = match[1];
          const mocksMatchean = parseo.filter(mock => mock.url.includes(capturoPalabra)).filter(mock => mock.extra);
          const miMock = mocksMatchean.find(mock => mock.extra.find(extra => response.config.url.includes(extra)));
          return adapter(response.data, miMock);
        }
      }
    } else return response.data;
  },
  error => {
    console.log(error.response);
    
    if(error.response?.data?.detail === "Usted no tiene permiso para realizar esta acción.") return eventBusService.dispatch(EXPIRED_SESSION_EVENT);
    if (error.response && (error.response.status === 401 || error.response.data.code === 'token_not_valid')) {
      if (error.response.data.code === 'token_not_valid') {
        // agregar logica para refrescar token
        eventBusService.dispatch(EXPIRED_SESSION_EVENT);
      } else
        return error.response;
    } else return Promise.reject(error);

  }
);

// const usarHeaderClientId = (route, method) => {
//   return clientIdEnHeader.find(val => route.indexOf(val.route) > -1 && val.methods.indexOf(method.toUpperCase()) > -1);
// }

export { DataService, loginKeyCloak };