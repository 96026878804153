import React from "react";
import { Modal } from "../modals/antd-modals";

const ConfirmarPass = ({confirmar, setConfirmar, onSave}) => {
    return <Modal
    type="primary"
    title="¿Confirma que desea guardar los cambios?"
    visible={confirmar}
    onOk={() => {
        onSave();
        setConfirmar(false);
    }}
    onCancel={() => setConfirmar(false)}
    forceRender={true}
    width={500}
    >
    </Modal>
};

export default ConfirmarPass;