import {useEffect, useState} from "react";

const useError = () => {

    const [error, setError] = useState([]);
    const [finalError, setFinalError] = useState([]);
    const [columns, setColumns] = useState([]);

    const getErrorField = (fieldName) => {
        let columnName
        const findColumnName = columns.find((x) => x.dataIndex === fieldName);

        if(!findColumnName) return columnName = "Error";

        if (typeof findColumnName.title !== "object") {
            columnName = findColumnName.title;
        } else {
            if (findColumnName.title.props.children) {
                columnName = findColumnName.title.props.children[0].props.children;
            } else {
                columnName = findColumnName.title.props.columnName;
            }
        }

        return columnName;
    }
    
    useEffect(() => {
        let errores = [];
        if(error.response?.status === 500) {
            return errores.push(`Error inesperado, vuelva a intentar en unos minutos`);
        }
        if (error.response && error.response.data) {
            for (const property in error.response.data) {
                if (property !== "non_field_errors") {
                    errores.push(
                        `${getErrorField(property) + ":" ?? ""} ${
                            error.response.data[property]
                          }`
                      );
                } else {
                    errores.push(`Error: ${error.response.data[property]}`);
                }
            }
        }
        setFinalError(errores);
        // eslint-disable-next-line
    }, [error]);

    return {finalError, setError, setColumns};
}

export default useError;