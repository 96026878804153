import React, {useEffect, useState } from "react";
import { Modal, Row, Col, Upload, message, Button, Form } from "antd";
import { getItem } from "../../../utility/localStorageControl";
import { useSelector, useDispatch } from "react-redux";
import ConfirmarPass from "../../../components/confirmarPass";
import useError from "../../../hooks/useError";

const SubirFotoPerfil = ({showEditImage, setShowEditImage}) => {

    const {usuario} = useSelector((state) => state.usuarioReducer);
    const [confirmarUser, setConfirmarUser] = useState(false);
    const dispatch = useDispatch();
    const [image, setImage] = useState("");
    const [confirmar, setConfirmar] = useState(false);
    const {finalError, setError} = useError();
    const [saving, setSaving] = useState(false);
    const [form] = Form.useForm();

    const beforeUpload = (file) => {
      const tipoImagen = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === "image/jpg";
    
      if (!tipoImagen) {
        message.error('Solamente puedes subir imágenes de tipo: PNG, JPG y JPEG');
      }
    
      const pesoImagen = file.size / 1024 / 1024 < 3;
    
      if (!pesoImagen) {
        message.error('El tamaño  de la imagen debe ser menor a 3 MB');
      }
    
      return tipoImagen && pesoImagen;
    };

    const getBase64 = (img) => {
      const reader = new FileReader();
      reader.readAsDataURL(img);
    };
    
    const onFinish = () => {
      setShowEditImage(false);
      dispatch({type: "usuario/modificarImageChange", payload: image});
    };

    const props = {
      name: 'photo',
      listType:"picture",
      action: `https://staging.api.mav-cpd.com.ar/users/${usuario.id}`,
      maxCount: 1,
      method: "PATCH",
      headers: {
        authorization: `Bearer ${getItem("loggedIn").access || ""}`
      },
      beforeUpload,
      onChange(info) {
        if (info.file.status === 'done') {
          getBase64(info.file.originFileObj);
          setImage(`${info.fileList[0].response.photo}`);
        }
      },
    };

      useEffect(() => {
        if(!showEditImage) return;
      }, [showEditImage]);
      

    return (
      <>
    <Modal visible={showEditImage} onCancel={() => setShowEditImage(false)} onOk={onFinish}>
        <Row>
            <Col>
              {showEditImage &&
              <Upload {...props}>
                <Button>Subir</Button>
              </Upload>}
            </Col>
        </Row>
    </Modal>
    {/* <ConfirmarPass confirmar={confirmar} setConfirmar={setConfirmar} onSave={beforeUpload}/> */}
    </>
    );
}

export default SubirFotoPerfil;
/*
    const validateImage = async (e) => {
      try {
        let newFormData = new FormData();
        newFormData.append("photo", e.target.files[0])
        await service.partialUpdate(usuario.id, e.target.files[0]);
        message.success('¡Imagen subida con éxito!');
      } catch (error) {
        console.log("ERROR", error);
        message.error('Ha habido un error en la subida');
      } 
    }
*/